<template>
    <div class="card">
        <Toast />

        <div class="flex align-items-center">
            <i class="pi pi-window-minimize mr-2" style="font-size: 1.5rem;"></i>
            <h3 style="margin:0;">Regras de Composição de Valores</h3>
        </div>
        <hr />

        <DataTable responsiveLayout="stack" :value="data" v-model:selection="selecionado" selectionMode="single"
            showGridlines @sort="sort($event)" @rowSelect="carregarItensRegra">
            <div class="flex align-items-center">
                <div class="field mr-2">
                    <label>
                        <strong>Descrição</strong>
                    </label>

                    <InputText class="w-full" v-model="filter.descricao" />
                </div>

                <div class="mr-2 mt-2">
                    <div class="p-inputgroup">
                        <Button label="Filtrar" icon="pi pi-filter" class="p-button-outlined p-button-info"
                            @click="carregarDados()"></Button>

                        <Button title="Limpar Filtro" icon="pi pi-filter-slash" class="p-button-outlined p-button-info"
                            style="margin-left: 1px;" @click="limparFiltro()"></Button>
                    </div>
                </div>

                <Button v-if="filter.situacao == 'ATIVO'" type="button" icon="pi pi-ban" label="Listar inativos"
                    class="p-button-outlined p-button-danger mr-2 mt-2"
                    @click="filter.situacao = 'INATIVO'; carregarDados()"></Button>

                <Button v-if="filter.situacao == 'INATIVO'" type="button" icon="pi pi-check-circle" label="Listar ativos"
                    class="p-button-outlined mr-2 mt-2" @click="filter.situacao = 'ATIVO'; carregarDados()"></Button>

                <Button label="Cadastrar" icon="pi pi-plus" class="p-button-info mt-2 mr-2" @click="cadastrar()"></Button>
            </div>
            <template #header>
                Composições de Valores
            </template>
            <template #empty>
                Nenhum registro encontrado.
            </template>
            <template #loading>
                Carregando... Por favor, aguarde.
            </template>
            <Column field="id" header="Código" sortable />
            <Column field="finalidade" header="Finalidade" />
            <Column field="descricao" header="Descricao" />

            <Column field="fixoDolar" header="Fixo em Dólar" dataType="boolean" class="centralizar-titulo-tabela" bodyClass="text-center">
              <template #body="{ data, index }" font-size: 20px;>
                <i v-if="data.fixoDolar" class="pi pi-check-circle" style="color: green; font-size: 20px;" title="Sim"></i>
                <i v-if="!data.fixoDolar" class="pi pi-times-circle" style="color: red; font-size: 20px;" title="Não"></i>
              </template>
            </Column>

            <Column header="Ações" style="width:9rem;" class="centralizar-titulo-tabela">
                <template #body="{ data }">
                    <div class="flex-none">
                        <Button title="Visualizar histórico" icon="pi pi-list"
                            class="p-button-rounded p-button-warning flex-none mr-2"
                            @click="visualizarHistorico(data)"></Button>
                        <Button v-if="data.ativo" title="Editar" icon="pi pi-pencil" class="p-button-rounded p-button-info flex-none mr-2"
                            @click="editar(data.id)"></Button>

                        <Button v-if="data.ativo" title="Inativar" class="p-button-rounded flex-none p-button-danger"
                            icon="pi pi-ban" @click="confirmarAtivarOuInativar(data)"></Button>

                        <Button v-if="!data.ativo" title="Ativar" class="p-button-rounded flex-none p-button-info"
                            icon="pi pi-check-circle" @click="confirmarAtivarOuInativar(data)"></Button>
                    </div>
                </template>
            </Column>
        </DataTable>

        <Paginator v-model:first="primeiraLinha" :rows="qtdRegistroPorPagina" :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]" @page="onPage" />

        <ItemComposicaoValorForm v-if="this.dto" :editavel="componenteItensEditavel" :dto="dto" :campo-invalido="campoInvalido"/>

        <Dialog v-model:visible="ativarOuInativarDialog" :style="{ width: '300px' }" header="Alerta" :modal="true">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>Tem certeza que deseja <strong>{{ selecionado.ativo ? 'inativar' : 'ativar' }}</strong> a Regra
                    Composição de Valor:
                    <strong>{{ selecionado.descricao }}</strong>?</span>
            </div>
            <template #footer>
                <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
                <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
            </template>
        </Dialog>

        <ModalHistoricoGenerico codigoPagina="COMPOSICAO_VALOR" :id="selecionado.id"
            :descricao="selecionado.descricao" :visivel="historicoDialog" @fechar="ocultarHistorico()" />

    </div>
</template>
<script>
import StorageService from '../../../service/StorageService';
import ComposicaoValorService from '../../../service/ComposicaoValorService';
import ItemComposicaoValorForm from './ItemComposicaoValorForm.vue';
import ModalHistoricoGenerico from '../../ModalHistoricoGenerico.vue';

export default {
    components: { ItemComposicaoValorForm, ModalHistoricoGenerico },

    data() {
        return {
            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 10,
            data: [],
            itemData: [],
            filter: {},
            selecionado: {},
            ordenar: 'id,asc',
            historicoDialog: false,
            descricaoHistoricoDialog: null,
            ativarOuInativarDialog: false,
            storange: StorageService.getControlePagina("COMPOSICAO_VALOR"),
            storangeMessage: StorageService.getMensagemToast("COMPOSICAO_VALOR"),
            campoInvalido: {},
            finalidades: [],
            operacoes: [],
            dto: {},
            componenteItensEditavel: false,

        }
    },

    created() {
        this.filter.situacao = "ATIVO";
        if (this.storange.default == undefined) {
            this.filter = this.storange.filtros;
        }
        this.pagina = this.storange.numero;
        this.primeiraLinha = this.storange.firstRow;
        this.qtdRegistroPorPagina = this.storange.qtdRegistro;
        this.finalidades = ComposicaoValorService.buscarFinalidadesEnum();
        this.operacoes = ComposicaoValorService.buscarOperacoesEnum();

        this.carregarDados();
        this.exibirMensagemToast();
    },

    methods: {

        exibirMensagemToast() {
            if (this.storangeMessage.active) {
                this.$toast.add({
                    severity: this.storangeMessage.severity,
                    summary: this.storangeMessage.summary,
                    detail: this.storangeMessage.detail,
                    life: 7500
                });
                StorageService.setMensagemToast("COMPOSICAO_VALOR", 'info', '', '', false);
            }
        },

        cadastrar() {
            this.$router.push(`/regrasComposicaoValores/cadastro`);
        },

        editar(terminalId) {
            this.$router.push(`/regrasComposicaoValores/editar/${terminalId}`);
        },

        preencherItensRegra(itens) {
            return itens
                .sort((objA, objB) => { return objA["ordem"] - objB["ordem"]; })
                .map(item => {
                    const itemCompValor = {};
                    itemCompValor["id"] = item.id;
                    itemCompValor["ordem"] = item.ordem;

                    itemCompValor["operacao"] = this.operacoes.find(operacao => { return operacao.codigo === item.operacao });
                    itemCompValor["indicadorValor"] = item.indicadorValor;
                    itemCompValor["referencia"] = item.referencia;

                    itemCompValor["valorPadrao"] = item.valorPadrao;
                    itemCompValor["altera"] = item.altera ? true : false;
                    itemCompValor["aFixar"] = item.aFixar ? true : false;
                    itemCompValor["ativo"] = item.ativo;

                    return itemCompValor;
                });
        },

        montarLista(lista) {
            return lista.map(data => {
                const dados = {};
                dados["id"] = data.id;
                dados["finalidade"] = this.finalidades.find(item => { return item.codigo === data.finalidade }).descricao;
                dados["descricao"] = data.descricao;
                dados["itens"] = data.itens;
                dados["itens"] = this.preencherItensRegra(data.itens);
                dados["itensDtoResponse"] = data.itens;
                dados["ativo"] = data.ativo;
                dados["fixoDolar"] = data.fixoDolar;
                return dados;
            });
        },

        carregarDados() {
            this.filter["page"] = this.pagina;
            this.filter["size"] = this.qtdRegistroPorPagina;
            this.filter["sort"] = this.ordenar;
            this.dto = {};

            ComposicaoValorService.buscarComPaginacao(this.filter)
                .then(({ data }) => {
                    if (data) {
                        this.data = this.montarLista(data.content);
                        this.qtdRegistroPorPagina = data.pageable.pageSize;
                        this.totalRegistro = data.totalElements;
                        this.dto = this.data[0];
                    }
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao buscar regras de composição de valor',
                        detail: `Falha não mapeada.`,
                        life: 7500
                    };

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });

            StorageService.setControlePaginaFilter("REGRAS-COMPOSICAO-VALORES", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.primeiraLinha = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows;
            this.carregarDados();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.ordenar = `${sortField},${sortOrder}`;
            this.carregarDados();
        },

        limparFiltro() {
            this.filter["descricao"] = undefined;
            this.carregarDados();
        },

        confirmarAtivarOuInativar(regraCompValor) {
            this.selecionado = regraCompValor;
            this.ativarOuInativarDialog = true;
        },

        visualizarHistorico(regraCompValor) {
            this.descricaoHistoricoDialog = "Regra: " + regraCompValor.descricao;
            this.historicoDialog = true;
            this.selecionado = regraCompValor;
        },

        ocultarHistorico() {
            this.historicoDialog = false;
            this.selecionado = {};
        },

        ativarOuInativar() {
            if (this.selecionado.ativo) {
                ComposicaoValorService.inativar(this.selecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Inativado',
                            detail: `A Regra foi inativada com sucesso.`,
                            life: 7500
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.selecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity: 'error',
                            summary: 'Falha ao inativar',
                            detail: `Falha não mapeada.`,
                            life: 7500
                        };

                        if (!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
            else {
                ComposicaoValorService.ativar(this.selecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Ativada',
                            detail: `A Regra foi ativada com sucesso.`,
                            life: 7500
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.selecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity: 'error',
                            summary: 'Falha ao ativar',
                            detail: `Falha não mapeada.`,
                            life: 7500
                        };

                        if (!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }

            this.ativarOuInativarDialog = false;
        },

        carregarItensRegra() {
            this.dto = this.selecionado;
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.ordenar = `${sortField},${sortOrder}`;
            this.carregarDados();
        }
    }
}

</script>