<template>
    <div class="card">
        <div class="flex justify-content-between">
            <div class="flex align-items-center">
                <i class="pi pi-comments mr-2" style="font-size: 1.4rem"></i>
                <h3 style="margin:0px 5px;">{{ id ? 'Editar' : 'Cadastrar'}} Regra Composição de Valores</h3>
            </div>
        </div>

        <hr />

        <div class="grid p-fluid formgrid mt-3">
            <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label>
                        <strong>Código</strong>
                    </label>
                    <InputText v-model="dto.id" :disabled="true" />
                </div>
            </div>

            <div class="col-12 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="finalidade">
                        <strong>Finalidade<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete id="finalidade" placeholder="Selecione..." dropdown v-model="dto.finalidade"
                        field="descricao" class="w-full" :suggestions="finalidadeFiltro" :forceSelection="true"
                        :class="{ 'p-invalid': campoInvalido.finalidade }" @complete="buscarFinalidade" />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                <div class="field">
                    <label>
                        <strong>Descrição<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dto.descricao" id="modalidade" />

                </div>
            </div>

            <div class="col-12 sm:col-1 md:col-1 lg:col-1 xl:col-1">
              <div class="field" >
                <label>
                  <strong>Fixo em Dólar</strong>
                </label>
              </div>
              <div style="margin-left:2rem;margin-top:-0.5rem">
                <Checkbox v-model="dto.fixoDolar" :binary="true" />
              </div>
            </div>

        </div>

        <ItemComposicaoValorForm :editavel="componenteItensEditavel" :dto="dto" :campo-invalido="campoInvalido" />

        <div class="flex align-items-center mt-3">
            <Button label="Salvar" icon="pi pi-save" class="p-button p-component p-button-info mr-2" @click="salvar()"
                :disabled="doubleClick"></Button>
            <Button label="Cancelar" icon="pi pi-times" class="p-button p-component p-button-danger mr-2"
                @click="voltar()"></Button>
        </div>
    </div>
</template>
<script>
import ComposicaoValorService from '../../../service/ComposicaoValorService';
import StorageService from '../../../service/StorageService';
import ItemComposicaoValorForm from './ItemComposicaoValorForm.vue';
export default {
    components: { ItemComposicaoValorForm },
    name: "RegrasCommposicaoValoresForm",
    props: {
        id: {
            type: Number,
            require: false
        }
    },

    data() {
        return {
            dto: {},
            editingRows: [],
            campoInvalido: {},
            finalidadeFiltro: [],
            finalidades: [],
            operacoes: [],
            componenteItensEditavel: true,
        }
    },

    methods: {
        voltar() {
            this.$router.push(`/regrasComposicaoValores`);
        },

        cancelar() {
            if (!this.editingRows && this.editingRows.length <= 0) return;
            this.editingRows = [];
        },

        editar(registro) {
            const registroAux = Object.assign({}, registro);
            this.editingRows = [registroAux];
        },

        carregarFinalidades() { },

        buscarFinalidade(event) {
            if (!event.query.trim().length) {
                this.finalidadeFiltro = [...this.finalidades];
            } else {
                this.finalidadeFiltro = this.finalidades.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        montarJsonTabela(itensCompValor) {
            return itensCompValor
                .filter(item => { return !!item.ordem && !!item.indicadorValor && item.operacao })
                .map(item => {
                    const itemCompValor = {};
                    itemCompValor["id"] = item.id;
                    itemCompValor["ordem"] = item.ordem;
                    itemCompValor["indicadorValor"] = item.indicadorValor;
                    itemCompValor["operacao"] = item.operacao.codigo;
                    if (!!item.referencia) itemCompValor["referencia"] = item.referencia;
                    if (!!item.valorPadrao) itemCompValor["valorPadrao"] = item.valorPadrao;
                    itemCompValor["altera"] = item.altera ? true : false;
                    itemCompValor["aFixar"] = item.aFixar ? true : false;

                    return itemCompValor;
                });
        },

        montarJson() {
            const obj = {};
            obj["id"] = this.id;
            obj["finalidade"] = this.dto.finalidade.codigo;
            obj["descricao"] = this.dto.descricao;
            obj["itens"] = this.montarJsonTabela(this.dto.itens);
            obj["fixoDolar"] = !this.dto.fixoDolar?false:true;

            return obj;
        },

        validarCamposObrigatorios() {
            let campos = [];

            if (!this.dto.finalidade) {
                campos.push("Finalidade");
                this.campoInvalido.finalidade = true;
            }
            else {
                this.campoInvalido.finalidade = false;
            }

            if (!this.dto.descricao) {
                campos.push("Descrição");
                this.campoInvalido.descricao = true;
            }
            else {
                this.campoInvalido.descricao = false;
            }

            if (!this.dto.itens[0].ordem) {
                campos.push("Itens da Regra");
                this.campoInvalido.itens = true;
            }
            else {
                this.campoInvalido.itens = false;
            }

            if (campos.length > 0) {
                let mensagem = `Por favor, preencher o campo: ${campos[0]}.`;

                if (campos.length > 1) {
                    mensagem = "Por favor, preencher os campos: ";
                    mensagem += campos.join(", ");

                    const indice = mensagem.lastIndexOf(", ");
                    mensagem = `${mensagem.substring(0, indice)} e ${mensagem.substring(indice + 1, mensagem.length)}.`;
                }

                this.$toast.add({
                    severity: 'warn',
                    summary: 'Não foi possível salvar a Regra de Composição de Valor.',
                    detail: mensagem,
                    life: 15000
                });
            }

            return campos.length === 0;
        },

        salvar() {
            if (!this.validarCamposObrigatorios()) return;
            const composicaoValorDto = this.montarJson();
            ComposicaoValorService.salvar(composicaoValorDto)
                .then(({ data }) => {
                    if (!!data) {
                        const detalhe = 'A Regra de código ' + data.composicaoValorDto.id + ' foi salva com sucesso.';
                        StorageService.setMensagemToast("COMPOSICAO_VALOR", 'success', 'Regra de composição de valor salva', detalhe, true);
                        this.voltar();
                    }
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Não foi possível salvar a Regra.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!",
                        life: 7500
                    }

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        preencherItensForm(itens) {
            return itens
                .sort((objA, objB) => { return objA["ordem"] - objB["ordem"]; })
                .map(item => {
                    const itemCompValor = {};
                    itemCompValor["id"] = item.id;
                    itemCompValor["ordem"] = item.ordem;

                    itemCompValor["operacao"] = this.operacoes.find(operacao => { return operacao.codigo === item.operacao });
                    itemCompValor["indicadorValor"] = item.indicadorValor;
                    itemCompValor["referencia"] = item.referencia;

                    itemCompValor["valorPadrao"] = item.valorPadrao;
                    itemCompValor["altera"] = item.altera ? true : false;
                    itemCompValor["aFixar"] = item.aFixar ? true : false;

                    return itemCompValor;
                });
        },

        preencherForm(dtoResponse) {
            const dados = {};
            dados["id"] = this.id;
            dados["finalidade"] = this.finalidades.find(item => { return item.codigo === dtoResponse.finalidade });
            dados["descricao"] = dtoResponse.descricao;
            dados["itens"] = this.preencherItensForm(dtoResponse.itens);
            dados["itensDtoResponse"] = dtoResponse.itens;
            dados["fixoDolar"] = dtoResponse.fixoDolar;
            this.dto = dados;
        },
    },

    mounted() {
        this.finalidades = ComposicaoValorService.buscarFinalidadesEnum();
        if (!!this.id) {
            this.operacoes = ComposicaoValorService.buscarOperacoesEnum();
            ComposicaoValorService.buscarPorId(this.id)
                .then(({ data }) => {
                    this.preencherForm(data.composicaoValorDto);
                })
                .catch(error => {
                    this.dados = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar os dados da Regra.`,
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!",
                        life: 7500
                    }

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message, exception } = error.response.data;

                        if (!!exception) {
                            toast["detail"] = exception;
                        }
                    }

                    this.$toast.add(toast);
                });
        }
    },
}
</script>